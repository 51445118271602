import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { connect } from 'react-redux'
import { setPopup } from '../../redux/actions/popupsActions'
import { getWatchlist } from '../../redux/actions/userRestActions'
import { VIDEOPLAYER } from '~/client/redux/popup-types'
import PlusButton from '../PlusButton'
import PlayButton from '../PlayButton'

import './styles.css'

function CarouselSlide ({
  slideData = {},
  // onTitleClick,
  mweb,
  index,
  setPopup,
  totalImages = 0,
  user,
  // isTablet,
  pageId
}) {
  const history = useNavigate()
  const [isAddToWl, setIsAddToWl] = useState(false)
  const carouselSlideDetailContainerRef = useRef(null)
  const lang = Cookies.get('userLanguage') || 'en'
  const [dataSource, setDataSource] = useState(false)
  const [webDisplaySrc, setWebDisplaySrc] = useState('')
  const [mwebDisplaySrc, setMwebDisplaySrc] = useState('')

  const { gist = {} } = slideData
  const { id: contentId, contentType, originalObjectId } = gist
  const { phoneNumber, email } = user
  const directorName = slideData?.creditBlocks[0]?.credits[0]?.title

  const watchListoptions = {
    contentId,
    contentType,
    position: 1,
    pageId,
    phoneNumber,
    email,
    directorName,
    platform: 'Web',
    videoData: slideData,
    user
  }
  // show icons - add to watchlist
  const showPlusIcon = user.watchlist || !user.auth

  const watchlist = user?.watchlist || []

  useEffect(() => {
    const watchlist = user?.watchlist || []
    const isAddedToWatchlist2 = watchlist.some(
      (id) => id === contentId || id === originalObjectId
    )
    setIsAddToWl(isAddedToWatchlist2)
  }, [watchlist])

  const attrs = {
    [isAddToWl ? 'data-minus-icon' : 'data-plus-icon']:
      JSON.stringify(watchListoptions)
  }

  const onPlayButtonClick = () => {
    const { gist, seasons, metadataMap } = slideData || {}
    let videoId = gist.id
    if (gist.contentType === 'SERIES') {
      videoId = seasons[0].episodes[0].id
    }
    setPopup(VIDEOPLAYER, {
      contentData: { videoId, gist, seasons, metadataMap }
    })
    document.body.classList.add('no-scroll-video-player-on')
  }
  // Clevertap Native Display

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('NativeData'))

    if (data) {
      setDataSource(data)
      setWebDisplaySrc(data?.kv?.Web)
      setMwebDisplaySrc(data?.kv?.Mweb)
    }
  }, [])

  const handleOnClick = (e) => {
    if (dataSource && index === 0) {
      window?.clevertap.renderNotificationClicked(dataSource)
      history('/subscribe')
    } else if (e.target === carouselSlideDetailContainerRef.current) {
      history(slideData?.gist?.permalink || '#')
    }
  }

  return (
    <div
      className={
        'carousel-slide-container swiper-lazy1 ' +
        (mweb && 'carousel-slide-mweb')
      }
      id='native-container'
      data-index={index}
      role='button'
      onClick={(e) => {
        handleOnClick(e)
      }}
      alt={slideData?.gist?.title}
    >
      <picture>
        <source
          media='(max-width: 479px)'
          srcSet={
            dataSource && index === 0
              ? mwebDisplaySrc
              : slideData.gist.imageGist._16x9 + '?impolicy=resize&w=500&h=282'
          }
        />
        <source
          media='(min-width: 480px) and (max-width: 1023px)'
          srcSet={
            dataSource && index === 0
              ? mwebDisplaySrc
              : slideData.gist.imageGist._16x9 +
                '?impolicy=resize&w=750&h=379.8'
          }
        />
        <source
          media='(min-width: 1024px) and (max-width: 1599px)'
          srcSet={
            dataSource && index === 0
              ? webDisplaySrc
              : slideData.gist.imageGist._32x9 + '?impolicy=resize&w=1120&h=630'
          }
        />
        <source
          media='(min-width: 1600px)'
          srcSet={
            dataSource && index === 0
              ? webDisplaySrc
              : slideData.gist.imageGist._32x9
          }
        />

        <img
          loading={index === 0 ? 'eager' : 'lazy'}
          fetchpriorty={
            index === 0 || index === 1 || index === totalImages - 1
              ? 'high'
              : 'auto'
          }
          alt={slideData?.gist?.title}
          height='100%'
          src={
            mweb
              ? slideData.gist.imageGist._16x9 +
                '?impolicy=resize&w=750&h=379.8'
              : slideData.gist.imageGist._32x9 + '?impolicy=resize&w=1120&h=630'
          }
          width='100%'
          style={{ position: 'absolute' }}
        />
      </picture>
      <div
        className='carousel-slide-details-container'
        ref={carouselSlideDetailContainerRef}
      >
        {!mweb && (
          <div className='carousel-slide-action-container'>
            <div className='carousel-slide-cta-container'>
              {dataSource && index === 0
                ? (
                  <></>
                  )
                : (
                  <PlayButton
                    playCtaText={lang === 'bn' ? 'প্লে নাউ ' : 'Play Now'}
                    onPlayButtonClick={onPlayButtonClick}
                    forCarousel
                  />
                  )}
            </div>
            {showPlusIcon && (
              <div className='carousel-slide-add-icon-container'>
                {dataSource && index === 0
                  ? (
                    <></>
                    )
                  : (
                    <PlusButton
                      watchListoptions={watchListoptions}
                      isAddToWl={isAddToWl}
                      attrs={attrs}
                      forCarousel
                    />
                    )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default connect(
  (state) => {
    return {
      user: state.user,
      isTablet: state.page?.isTablet,
      pageId: state.pageSettings?.id
    }
  },
  { setPopup, getWatchlist }
)(CarouselSlide)
